// call an end point to get configuration data

require('noty/src/noty.scss');
require('noty/src/themes/bootstrap-v3.scss');
require('noty/src/themes/bootstrap-v4.scss');
require('noty/src/themes/light.scss');
require('noty/src/themes/metroui.scss');
require('noty/src/themes/mint.scss');
require('noty/src/themes/nest.scss');
require('noty/src/themes/relax.scss');
require('noty/src/themes/semanticui.scss');
require('noty/src/themes/sunset.scss');
require('./animate.css');
require('./style.scss');

require("./App");