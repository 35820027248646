const Storage = {
    setValue : (key, value) => {
        const stringValue = JSON.stringify(value);
        window.localStorage.setItem(key, stringValue);
    },
    getValue : (key, defaultValue = null) => {
        const value = window.localStorage.getItem(key);
        if(value === null) return defaultValue;
        return JSON.parse(value);
    }
};


export default Storage;